import React, { useState } from 'react';
import StyledDropdownWithLinks from './DropdownWithLinks.style';
import Typography from './global/Typography';
import SlideUp from './global/SlideUp';
import Icon from './global/Icon';
import { useEffect } from 'react';

const DropdownWithLinks = ({ categoryName, links, p, forceOpen = false }) => {
  const [_isExtended, setIsExtended] = useState(false);
  const isExtended = forceOpen || _isExtended;

  return (
    <StyledDropdownWithLinks {...p}>
      <div
        className={'category-name' + (isExtended ? ' active' : '')}
        onClick={
          forceOpen
            ? undefined
            : () => {
                setIsExtended(!isExtended);
              }
        }
      >
        <h4>{categoryName}</h4>
        <Icon
          className="dropdown-arrow"
          width="8"
          height="4"
          d="M4 4L0 0H8L4 4Z"
        />
      </div>
      <SlideUp innerTag={'ul'} open={isExtended}>
        {links?.map((item, index) => (
          <li key={item.link_name}>
            <a href={item.link.url.href}>{item.link_name}</a>
          </li>
        ))}
      </SlideUp>
      <div className="divider"></div>
    </StyledDropdownWithLinks>
  );
};

export default DropdownWithLinks;
