import React, { useEffect, useState } from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import StyledFooter from './module.style';
import DropdownWithLinks from '../../components/DropdownWithLinks';
import ContactWithIcon from '../../components/ContactWithIcon';
import Typography from '../../components/global/Typography';
import Container from '../../components/global/Container';
import { css } from '@emotion/react';
import Icon from '../../components/global/Icon';
import Picture from '../../components/global/Picture';
import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  Phone,
  Whatsapp,
  Person,
} from '../../app/helpers/icons';
import RichText from '../../components/global/RichText';
import { useBreakpoint } from '../../app/providers/breakpointProvider';
import OpenHours from '../../components/OpenHours';
import { DateTime } from 'luxon';
import isEmbedded from '../../app/helpers/isEmbedded';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const FooterModule = ({
                        banner,
                        dropdownsData,
                        openHours,
                        phoneContact,
                        whatsappContact,
                        mailContact,
                        werkenBijContact,
                        socialMedia,
                        labels,
                        legalFooter,
                        ...p
                      }) => {
  const dropdownSections = JSON.parse(dropdownsData?.values || '[]');
  const bannerSpacing = useBreakpoint().select({
    mobile: '20px',
    desktopSmall: '30px',
  });
  const itemsAsDropdown = useBreakpoint().isDown('desktopSmall');
  const showFooter = !isEmbedded();


  console.log(werkenBijContact);

  return (
    <>
      {showFooter && (
        <>
          <StyledFooter>
            <Container
              {...p}
              pt={bannerSpacing}
              pb={bannerSpacing}
              pl={bannerSpacing}
              pr={bannerSpacing}
              yMarginOutside={false}
              flex
              col
              color="pacificBlue"
              className="blue-banner"
            >
              <Typography
                as="h2"
                variant="heading2"
                css={css`
                    text-align: center;
                `}
              >
                {banner.text}
              </Typography>
            </Container>
            <Container
              flex
              col
              color="bahamaBlue"
              yMarginOutside={false}
              className="main-footer"
              pt="15px"
              pb="15px"
            >
              <ul className="dropdowns-w-links">
                {dropdownSections.map((section) => (
                  <DropdownWithLinks
                    forceOpen={!itemsAsDropdown}
                    key={section.title}
                    categoryName={section.title}
                    links={section.links}
                  ></DropdownWithLinks>
                ))}
                <li className="contact-section">
                  <h4>Contact</h4>
                  <OpenHours openHours={openHours}></OpenHours>
                  <div className="support">
                    <ContactWithIcon
                      iconPath={Phone.d}
                      iconSize={Phone.size}
                      href={phoneContact.link}
                      target={phoneContact.newTab}
                      underline={true}
                    >
                      <RichText content={phoneContact.content} />
                    </ContactWithIcon>
                    <ContactWithIcon
                      iconPath={Whatsapp.d}
                      iconSize={Whatsapp.size}
                      href={whatsappContact.link}
                      target={whatsappContact.newTab}
                      underline={true}
                    >
                      <RichText content={whatsappContact.content} />
                    </ContactWithIcon>
                    <ContactWithIcon
                      iconPath={Mail.d}
                      iconSize={Mail.size}
                      href={mailContact.link}
                      target={mailContact.newTab}
                      underline={true}
                    >
                      <RichText content={mailContact.content} />
                    </ContactWithIcon>

                    <ContactWithIcon
                      iconPath={Person.d}
                      iconSize={Person.size}
                      href={werkenBijContact.link}
                      target={werkenBijContact.newTab}
                      underline={true}
                    >
                      <RichText content={werkenBijContact.content} />
                    </ContactWithIcon>
                  </div>
                  <div className="socials">
                    <a
                      href={socialMedia.fb}
                      target="_blank"
                      aria-label="facebookLink"
                      rel="noreferrer"
                    >
                      <Icon {...Facebook} />
                    </a>
                    <a
                      href={socialMedia.ld}
                      target="_blank"
                      aria-label="linkedinLink"
                      rel="noreferrer"
                    >
                      <Icon {...LinkedIn} />
                    </a>
                    <a
                      href={socialMedia.insta}
                      target="_blank"
                      aria-label="instagramLink"
                      rel="noreferrer"
                    >
                      <Icon {...Instagram} />
                    </a>
                  </div>
                </li>
              </ul>
              <div className="divider"></div>
              <div className="labels">
                {labels.map((label, ix) => (
                  <Picture
                    key={ix}
                    images={label.images}
                    width={label.width}
                    height={label.height}
                    alt={label.alt}
                  />
                ))}
                {/*<Picture alt='KiFiD logo' images={
            1:""
          } />
          <img src={labels.firstUrl} alt="Label logo" />
        <img src={labels.secondUrl} alt="Label logo" />*/}
              </div>
            </Container>
            <Container className="legal-footer" yMarginOutside={false}>
              <div className="links">
                <a
                  href={legalFooter.sitemap}
                  target={legalFooter.sitemapNewTab}
                >
                  Sitemap
                </a>
                <a
                  href={legalFooter.privacy}
                  target={legalFooter.privacyNewTab}
                >
                  Privacy
                </a>
                <a
                  href={legalFooter.legalMentions}
                  target={legalFooter.legalMentionsNewTab}
                >
                  Algemene voorwaarden
                </a>
              </div>
              <p>©{DateTime.now().year} Autokan.nl</p>
            </Container>
          </StyledFooter>
        </>
      )}
    </>
  );
};

export default FooterModule;

registerModule({ Footer: Self });
