import React from 'react';
import StyledOpenHours from './OpenHours.style';

const OpenHours = ({
    openHours,
    ...p
}) => {
    return (
        <StyledOpenHours {...p}>
            <div className="open-hours">
              <div className="open-hours__elm">
                <p>{openHours?.weekDays}</p>
                <p>{openHours?.sat}</p>
              </div>
              <div className="open-hours__elm">
                <p>{openHours?.openHoursWeek}</p>
                <p>{openHours?.openHoursSat}</p>
              </div>
            </div>
        </StyledOpenHours>
    );
};
export default OpenHours;
