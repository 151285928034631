import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { lighten } from 'polished';
import DropdownWithLinksStyle from '../../components/DropdownWithLinks.style';
import { pxToRem } from '../../theme/Theme';

export default styled.footer`
  --layout-extra-pl: 0px;
  --layout-extra-pr: 0px;
  ${({ theme }) => theme.typography.bodySemibold};
  margin-top: 30px;
  ${({ theme }) => theme.media.desktopSmall} {
    margin-top: 60px;
  }

  max-width: 100vw;

  .main-footer {
    h4 {
      ${({ theme }) => theme.fonts.september.bold};
    }
    ul.dropdowns-w-links {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    ${({ theme }) => theme.media.desktopSmall} {
      --pb: 60px;
      --pt: 60px;
      .divider {
        display: none;
      }
      ul.dropdowns-w-links {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
      h4 {
        ${({ theme }) => css`
          ${theme.fonts.september.bold};
          font-size: ${pxToRem(24)};
          line-height: 120%;
          ${theme.media.desktopSmall} {
            font-size: ${pxToRem(28)};
          }
        `}
      }
      ${DropdownWithLinksStyle} {
        .category-name {
          color: inherit;
          cursor: unset;
        }
        .dropdown-arrow {
          display: none;
        }
      }
    }

    .contact-section {
      padding: 15px 0 20px 0;

      p {
        margin: 0;
      }

      .open-hours {
        margin: 20px 0;

        &__elm:first-of-type > * {
          font-weight: ${({ theme }) => theme.weights.regular};
        }
      }

      .socials {
        margin-top: 30px;
        display: flex;

        & > a {
          margin-right: 15px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          transition: color 150ms linear;
          color: ${({ theme }) => theme.colors.white};
          ${({ theme }) => theme.media.hover} {
            &:hover {
              color: ${({ theme }) => lighten(0.2, theme.colors.pacificBlue)};
            }
          }
        }
      }
    }

    .divider {
      height: 1px;
      background-color: ${({ theme }) => theme.colors.quartz};
      opacity: 0.4;
    }

    .labels {
      padding-top: 20px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      picture {
        max-height: 40px;
        margin-right: 30px;
      }
    }
  }

  .legal-footer {
    --pt: 20px;
    --pb: 20px;
    --pl: 15px;
    --pr: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.bahamaBlue};
    display: flex;
    gap: 10px 30px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .links {
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }
    p {
      margin: 0;
    }
    a {
      color: ${({ theme }) => theme.colors.pacificBlue};
      ${({ theme }) => theme.typography.underline};

      ${({ theme }) => theme.media.hover} {
        &:hover {
          color: ${({ theme }) => lighten(0.2, theme.colors.pacificBlue)};
        }
      }
    }
  }
`;
