import styled from '@emotion/styled';
import { lighten } from 'polished';

export default styled.li`
  list-style: none;

  .category-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0 10px 0;
    cursor: pointer;

    transition: color 150ms linear;

    ${({ theme }) => theme.media.hover} {
      &:hover {
        color: ${({ theme }) => lighten(0.2, theme.colors.pacificBlue)};
      }
    }

    svg {
      transition: transform 200ms ease-in-out;
    }

    &.active {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    list-style: none;
    overflow: hidden;
    padding-inline-start: 0;
    padding: 0;

    li {
      margin: 5px 0;
    }

    a {
      color: ${({ theme }) => theme.colors.quartz};
      ${({ theme }) => theme.typography.underline};
      ${({ theme }) => theme.typography.bodySemibold};
      transition: color 150ms linear;
      cursor: pointer;

      ${({ theme }) => theme.media.hover} {
        &:hover {
          color: ${({ theme }) => lighten(0.2, theme.colors.pacificBlue)};
        }
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.quartz};
    opacity: 0.4;
    margin: 10px 0 5px 0;
  }
`;
