import styled from '@emotion/styled';

export default styled.div`
  .open-hours {
    display: flex;

    &__elm {
      display: flex;
      flex-direction: column;
      width: fit-content;

      p {
        margin: 0;
      }

      &:first-of-type {
        margin-right: 70px;
      }

      &:last-child {
        font-weight: ${({ theme }) => theme.weights.semibold};
      }
    }
  }
`;
